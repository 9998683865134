<template>
  <div>
    <div class="sctp-container sctp-pad-tb10 container clear">
      <!--  左侧菜单  -->
      <div class="left-menu sctp-fl sctp-bg-white">
        <template v-for="menu in linkArray">
          <div :key="menu.name">
            <div class="title">
              <template v-if="menu.icon">
                <i :class="[menu.icon]"></i>
              </template>
              <h3>{{ menu.name }}</h3>
            </div>
            <ul>
              <template v-for="link in menu.children">
                <li :key="link.name">
                  <router-link :to="link.url">{{ link.name }}</router-link>
                </li>
              </template>
            </ul>
          </div>
        </template>
      </div>
      <div class="right-content sctp-fl">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'shopIndex',
  components: {},
  data() {
    return {
      linkArray: [
        {
          name: '用户管理',
          icon: 'el-icon-s-custom',
          children: [
            {name: '用户信息', url: '/account/userbaseinfo'},
            {name: '账号管理', url: '/user/account'},
            {name: '保证金管理', url: '/account/cashdeposit'},
            {name: '资金管理', url: '/account/cashflow'},
            {name: '交易风险提示', url: '/account/riskwarning'},
          ]
        },
        {
          name: '需求管理',
          icon: 'el-icon-s-promotion',
          children: [
            {name: '我发布的需求', url: '/requirement/myrelease'},
            {name: '我参与的需求', url: '/requirement/myjoining'}
          ]
        },
        {
          name: '订单管理',
          icon: 'el-icon-s-order',
          children: [
            {name: '我的源码/设计/文档订单', url: '/user/order'},
            {name: '我的服务订单', url: '/user/service/order'},
            {name: '我的收藏', url: '/user/favorite'}
          ]
        },
        {
          name: '兼职管理',
          icon: 'el-icon-s-opportunity',
          children: [
            {name: '个人履历', url: '/user/resume'},
            {name: '作品集', url: '/user/works'},
            {name: '发布兼职', url: '/user/job'},
          ]
        }
      ]
    }
  },
  computed: {}
}
</script>

<style scoped lang="scss">
.container {
  .left-menu {
    position: relative;
    width: 180px;
    margin-right: 10px;
    padding: 10px 0;

    .title {
      padding-left: 24px;
      cursor: pointer;
      line-height: 40px;
      display: flex;
      align-items: center;

      i {
        margin-right: 5px;
        width: 24px;
        text-align: center;
        font-size: 18px;
        color: #909399;
      }

      h3 {
        font-weight: bold;
      }
    }

    ul {
      li {
        border-left: 2px solid transparent;

        :hover {
          background-color: rgba(255, 153, 0, .2);
        }

        a {
          display: flex;
          height: 40px;
          align-items: center;
          padding-left: 24px;

          &.router-link-exact-active {
            background-color: $main-color;
            color: white !important;
          }
        }
      }
    }
  }

  .router-link-active {
    color: #F90;
  }

  .right-content {
    width: calc(100% - 190px);
  }
}
</style>
